<template>
  <div class="btn">{{content}}</div>
</template>

<script>
export default {
    props:['content'],
  data() {
    return {
    };
  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.btn {
  padding: 5px;
  width: 80px;
  background: linear-gradient(to bottom right, #efcccb, #de1c14);
  font-size: 12px;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
}
</style>