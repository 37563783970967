<template>
  <div class="home" >
    <div class="header">
      <div style="width:16.5rem;">
      <form action="/">
        <van-search
          v-model="value"
          shape="round"
          placeholder="搜索"
          
          @search="searchGoods"
          @cancel="onCancel"
          @clear="onCancel"
          :left-icon="require('../assets/images/home_icon/搜索.png')"
        >
          <template #left-icon>
            <div class="icon-center">
              <van-icon style="pointer-events:none;width:1rem;height:1rem;" :src="require('../assets/svg/查找icon.svg')" size="1rem"  tag="embed" type="image/svg+xml" />
            </div>
          </template>
        </van-search>
        </form>
      </div>
      <div @click="toCartPage">
        <van-icon style="pointer-events:none;width:1.25rem;height:1.25rem;"  :src="require('../assets/svg/购物icon.svg')"   tag="embed" type="image/svg+xml" />
      </div>
      <div style>
        <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="onSelect"
          placement="bottom-end"
          style="right:10px ;"
        >
          <div class="my-pop">
            <div class="item-box">
              <div @click="showPopover = false;$toPage('/orderman')">
                <van-icon style="pointer-events:none;margin-right:.5rem;width:1.875rem;height:1.875rem;"  :src="require('../assets/svg/订单icon.svg')" size="1.875rem"  tag="embed" type="image/svg+xml" />
                订单
              </div>
              <div @click="showPopover = false">
                <van-icon style="pointer-events:none;margin-left:.2rem;margin-right:.5rem;width:1.625rem;height:1.625rem;"  :src="require('../assets/svg/消息icon.svg')" size="1.625rem"  tag="embed" type="image/svg+xml" />
                消息
              </div>
            </div>
          </div>
          <template #reference>
            <div class="my-center">
              <van-icon style="pointer-events:none;width:1.25rem;height:1.25rem;" :src="require('../assets/svg/更多icon.svg')" size="1.25rem"  tag="embed" type="image/svg+xml" />
            </div>
          </template>
        </van-popover>
      </div>
    </div>
    <!-- 首页轮播 -->
    <div class="home-swiper">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <van-image src="/static/upload/images/home/home_1.jpg" fit="cover" >
          <template v-slot:loading>
            <van-loading type="spinner" size="1.25rem" />
          </template>
          </van-image>
        </van-swipe-item>
        <van-swipe-item>
          <van-image src="/static/upload/images/home/home_1.jpg" fit="cover" >
          <template v-slot:loading>
            <van-loading type="spinner" size="1.25rem" />
          </template>
          </van-image>
        </van-swipe-item>
      </van-swipe>
    </div>
   
    <!-- 商品分类 -->
    <div class="goods_class">HPV 解决方案</div>
    <!-- 商品面板 -->
    <div class="goods_box" v-if="goodsList.length !== 0 || !showSearch">
      <div
        class="goods_pre"
        @click="$toPage('/detail/' + item.id)"
        v-for="(item,index) in goodsList"
        :key="index+99"
      >
        <div class="goods_img">
          <van-image width="100%" height="100%" :src="item.images" >
            <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
          </van-image>
        </div>
        <div class="msg_box">
          <div class="goods_title">{{item.title}}</div>
          <div class="goods_dsc">智能控制 次抛设计 多种给药模式</div>
          <div class="goods_price">￥{{item.original_price}}</div>
        </div>
      </div>
    </div>
    <!-- 空 -->
    <div v-else>
      <van-empty
        class="custom-image"
        :image="require('../assets/images/icon/空状态@2x.png')"
        description="没有搜索到响应商品"
      >
      <div>
        <div class="btn" @click="onCancel">
          返回商品页
        </div>
      </div>
    </van-empty>
    </div>
    <div class="footer-bottom">
      <div class="footer-text text-center">
        <p>Copyright © 2022 鸿飞腾业科技（杭州）有限公司 All Rights Reserved. <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">浙ICP备2021037531号-1</a></p>
      </div>
    </div>
    <van-popup v-if="false"  v-model="showBind" position="bottom" :style="{ height: '30%',zIndex:'999999'}" >
      <div class="bind-container">
        <div>当前未完成设备激活</div>
        <div>设备激活后提供服务</div>
        <div class="bind-op" v-if="false">
          <span @click="showBind = false">不绑定</span>
          <span @click="toBind">绑定<van-icon name="arrow" style="margin-left:20px;" /></span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import GoodsPre from "@/components/goods/GoodsPre.vue";

export default {
  name: "Home",
  components: {
    GoodsPre
  },
  props:['userInfo','needBind'],
  data() {
    return {
      url:window.location.href,
      originalGoodsList:[],
      goodsList: [],
      myCart: {
        items: []
      },
      //userInfo: {},
      value: "",
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: "订单", icon: "orders-o", path: "/orderman" }],
      showSearch:false,
      showBind:false
    };
  },
  watch:{
    needBind(val){
      console.log('值改变了',val)
      if(val){
        return this.showBind = true
      }
    }
  },
  computed: {
    items_count() {
      let count = 0;
      this.myCart.items.forEach(x => {
        count += x.count;
      });
      return count;
    },

  },
  created() {
    /*
    console.log('needBind',this.needBind)
    if(this.needBind){
      this.showBind = true
    }
    //this.userInfo = this.$bus.$data.userInfo;
    this.$bus.$on('tobind',()=>{console.log(111)})
    
    console.log('页面重新进入')
    */
   this.getGoodList();
  },
  mounted() {
    
  },

  methods: {
    toCartPage(path){
      //return this.$router.push('/cart')
      this.$toPage('/cart')
    },
    handleA(){
      console.log('111')
    },
    toBind(){
      let url = `https://www.machineeye.cn/bind/index.html?token=${window.sessionStorage.token}/#`
      window.location = url
    },
    onCancel(){
      this.value = ''
      this.showSearch = false
      this.getGoodList()
    },
    searchGoods() {
      if(!this.value){
        return this.getGoodList()
      }
      let goodsList = this.originalGoodsList;
      goodsList = goodsList.filter(x => {
        return x.title.indexOf(this.value) !== -1;
      });
      this.goodsList = goodsList;
      this.showSearch = true
      console.log(goodsList)
    },
    onSelect(action) {
      //this.$toast(action.text);
      this.$router.push(action.path);
    },
    addCart(item) {
      let needPush = true;
      console.log(item);
      this.myCart.items.forEach((x, index) => {
        if (x.id == item.id) {
          this.myCart.items[index].count += 1;
          this.myCart.items[index].price = Number(x.original_price) * x.count;
          needPush = false;
        }
      });
      if (needPush) {
        this.myCart.items.push({
          count: 1,
          id: item.id,
          images: item.images,
          title: item.title,
          original_price: item.original_price,
          price: Number(item.original_price)
        });
      }
      window.sessionStorage.setItem("myCart", JSON.stringify(this.myCart));
      this.editCart();
    },
    async getGoodList() {
      let { data: res } = await this.$http.post("api/goodList");
      res.data.list = res.data.list.filter(x=>{
        return x.is_delete_time == 0
      })
      this.goodsList = res.data.list.filter(x=>x.id!=1);
      this.originalGoodsList = res.data.list.filter(x=>x.id!=1);
    },
    async editCart() {
      console.log(this.myCart);
      let items = this.myCart.items.map(x => {
        x.goods_id = x.id;
        x.image = x.images;
        return x;
      });
      let { data: res } = await this.$http.post("/api/editGoodsCart", {
        items
      });
      if (res.statusCode !== 0) {
        this.$toast.fail(res.msg);
      }
      console.log("加入购物车成功");
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.van-field__clear{
  margin-right: 0px !important;
}
/deep/.van-popover__arrow{
  right: 0 !important;
}
.my-pop{
  width: 6.375rem;
  height: 7.9375rem;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .item-box{
    width: 100%;
    >div{
      padding: .75rem 0 .75rem .4375rem;
      font-size: 1.125rem;
      display: flex;
      align-items: center;

    }
    
    >div:first-child{
    border-bottom: 1px solid rgba(231, 231, 231, 1);
  }
  }
  
}
.icon-center{
  width: 1rem;
  height: 1.55rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-center{
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bind-container{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  >div{
    padding: 5px;
    font-size: 18px;
  }
  >div:first-child{
    color:var(--fc--);
    font-size: 20px;
    font-weight: bold;
  }
  .bind-op{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    >span{
      width: 50%;
      color:#666;
    }
    >span:last-child{
      border-left: 1px solid var(--fc--);
      color: var(--fc--);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.btn{
      width: 10rem;
      height: 2.5rem;
      color: #fff;
  
      background-color:var(--fc--);
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
/deep/.van-popover__action {
  width: 100px !important;
}
/deep/.van-popover__arrow {
  right: 0 !important;
}
.header {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: calc(2.75rem + 2.75rem);
  padding: .5rem 1.25rem .625rem .9375rem;
  position: fixed;
  border-top: 2.75rem solid #fff;
  top:0;
  left: 0;
  width: 100%;
  z-index: 999;
  .van-search {
    padding: 0 !important;
    height: 1.6875rem !important;
  }
  .van-search__content{
    padding-left:.5625rem;
    height: 1.6875rem;
    background-color: #fff;
    border: 1px solid rgba(210, 210, 210, 1);
  }
  .van-search .van-cell {
    padding: 0;
  }
  .van-cell {
    font-size: .6875rem;
    line-height: 1.5625rem;
  }
}
.home-swiper{
  display:flex;
  justify-content:center;

}
.home {
  height: 100vh;
  background-color: #f9f9f9;
  position: relative;
  padding-top: calc(3.71rem + 2.75rem);

  padding-bottom: 0 !important;
  .goods_class {
    text-align: left;
    color: #f4726e;
    padding: 12px;
    font-weight: 550;
  }
  .goods_box {
    background-color: #f9f9f9;
    padding:0 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 70vh;
    .goods_pre {
      width: calc(50vw - 1.125rem);
      
      background-color: #fff;
      text-align: left;
      margin-bottom: 10px;
      border-radius: .625rem;
      overflow: hidden;
      height: calc(50vw + 4.5em);
      .msg_box {
        padding: 5px;
      }
      .goods_img {
        height: calc(50vw - 18px);
      }
      .goods_title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goods_dsc {
        font-size: 10px;
        color: #999;
        padding: 5px 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .goods_price {
        color: #f6736e;
        font-size: 16px;
      }
    }
  }
  .my-swipe {
    width: calc(100vw - 24px);
    height: 8.8125rem;
    border-radius: .625rem;
  }
  .van-swipe-item {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/.van-image__img{
      border-radius: .625rem;
    }
  }
  .router_box {
    height: 70px;
    display: flex;
    background-color: #f9f9f9;
    padding: 20px 0;
    > div {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 12px;
      color: #f5736e;
    }
  }
  .border {
    margin: 0 5px;
    border-bottom: 2px solid #f9f9f9;
  }
  .border:last-child {
    border: none;
  }
}
.footer-bottom{
  font-size: 12px;
}
</style>
