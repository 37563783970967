import Vue from '../vant.config.js'
import App from './App.vue'
import router from './router'
import '@/assets/global.css'
// import Vant from 'vant';
// import 'vant/lib/index.css';
import MyBtn from "@/components/common/MyBtn.vue";

import axios from './http.js'





//Vue.use(Vant);
Vue.use(MyBtn)

Vue.config.productionTip = false

Vue.prototype.$toPage = (path)=>{
  
  router.push(path)
  return 
  let base = `www.machineeye.cn`
  let openUrl = `${location.href.split('#')[0]}#${path}`
  let enUrl = openUrl.split('').map((x)=>{
    return x.charCodeAt(0).toString(16)
  }).join('')
  console.log(enUrl)
  let url =  `jkey://bridge?action=openWebPage&url=${encodeURI(openUrl)}`
  //location = url
  //alert(`将要跳转的地址${url}`)
  window.open(url)
}
Vue.prototype.$closePage = ()=>{
  router.push('/')
  return 
  let url = 'jkey://bridge?action=closeWebPage'
  //alert(`调用closeWebPage，即将关闭该webview`)
  location = url
}
Vue.prototype.$getUser = ()=>{
  return JSON.parse(localStorage.getItem('userInfo')) 
}
Vue.prototype.$bus = new Vue()
Vue.prototype.$http = axios
Vue.prototype.$getParams = function () {
    let url = window.location.href
    let params = url.split('?')[1].split('&')
    if (params.length == 0) {
      return false
    }
    let paramsObj = {}
    for (let i of params) {
      let [k, v] = i.split('=')
      paramsObj[k] = v
    }
    return paramsObj
  }

/*
router.beforeEach((to, form, next) => {
  if (to.path == '/' || to.path == '/home') {
    window.sessionStorage.setItem('toJkey','1')
    console.log('进入首页')
    return next()
  }
  if (window.sessionStorage.getItem('isLogin') !== '1') {
    //window.v_toast.fail('登录过期请重新登录')
    Vue.prototype.$toast.fail('登录过期请重新登录')
    Vue.prototype.$bus.$emit('tobind')
    return next(false)
  }
  if (!JSON.parse(window.sessionStorage.getItem('userInfo')).doctor_id) {
    //window.v_toast.fail('登录过期请重新登录')
    Vue.prototype.$toast.fail('设备未激活')
    return next(false)
  }
  // if ((form.path == '/' || form.path == '/home') && window.sessionStorage.getItem('toJkey') === '1') {
  //   window.sessionStorage.setItem('toJkey','0')
  //   let url = window.location.href.split('#')[0] + '#' + to.path
  //   window.location = `jkey://jsBridge?action=webPage&data={"url" : "${url}"}`
  //   return next(false)
  // }
  Vue.prototype.$bus.$emit('handleChangePath', to.path)
  next()
})
*/
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

