import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{ 
    path: '/',
    
    redirect:{
      path:'/home'
    }
  },
  {
    path:'/home',
    name:'Home',
    component:Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
    children: [{
        path: '/about/a'
      },
      {
        path: '/b'
      }
    ]
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: () => import('../components/goods/GoodsDetail.vue'),
  },
  {
    path: '/confirmorder',
    name: 'ConfirmOrder',
    component: () => import('../components/order/ConfirmOrder.vue'),
  },
  {
    path: '/address',
    name: 'UserAddress',
    component: () => import('../components/order/UserAddress.vue'),
  },
  {
    path: '/editaddress',
    name: 'EditAddress',
    component: () => import('../components/order/EditAddress.vue'),
  },
  {
    path: '/orderman',
    name: 'OrderMan',
    component: () => import('../components/OrderMan.vue'),
  },
  {
    path: '/orderdetail/:id',
    name: 'OrderDetail',
    component: () => import('../components/order/OrderDetail.vue'),
  },
  {
    path: '/addaddress',
    name: 'AddAddress',
    component: () => import('../components/order/AddAddress.vue'),
  },
  {
    path: '/cart',
    name: 'GoodsCart',
    component: () => import('../components/goods/GoodsCart.vue'),
  },
  {
    path: '/paystatus/:status',
    name: 'PayStatus',
    component: () => import('../components/pay-status/PayStatus.vue'),
  }
]

const router = new VueRouter({
  routes
})

export default router