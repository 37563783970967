<template>
  <div id="app">
    <div style="none">
      <a href="tel:400-777-7776" id="customer"></a>
    </div>
    <transition name="fade">
      <div @click="removeMenu" >
        <router-view  style="overflow-y:auto;padding-bottom:100px;" :user-info="userInfo" :need-bind="needBind" @changeCart="handleChangeCart" @callCustomer="handleCallCustomer" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "/",
      show: false,
      showMenu:false,
      needBind:false,
      myCart: {
        items: []
      },
      userInfo:{},
      isContinue:true
    };
  },
  async created() {
    window.v_toast = this.$toast

    let token = window.location.search.split('token=')[1]
    window.localStorage.setItem('token', token)
    window.sessionStorage.setItem('token', token)
    this.getUserInfo()
  },
  mounted() {

    this.$bus.$on("add-cart", item => {
      this.addCart(item);
    });
    //window.self.focus();
    
  },
  methods: {
    handleCallCustomer(){
      let a = window.document.getElementById('customer')
      a.click()
    },
    handleChangeCart(item){
      this.myCart = item
    },
    changePath(x) {
      console.log(x);
    },
    openOrder() {
      this.$router.push("/orderman");
      this.showMenu = false
    },
    removeMenu(){
      if(this.showMenu){
        this.showMenu = false
      }
    },
    async login(){
      let {data:res} = await this.$http.post('/api/login',{
        mobile:13247841995,
        password:13247841995
      })
      if(res.statusCode !== 0){
        return this.$toast.fail(res.msg)
      }
      this.$toast.success('登录成功')
      this.$bus.$data.userInfo = res.data
      window.sessionStorage.setItem('userInfo',JSON.stringify(res.data))
      window.sessionStorage.setItem('token',res.data.token)
    },
    async getUserInfo(){
      let {data:res} = await this.$http.post('/api/getUserInfoByToken')
      if(res.statusCode !== 0){
        return this.$toast.fail('登录过期，请重新登录')
      }
      this.$bus.$data.userInfo = res.data
      console.log(res.data)
      this.userInfo = res.data
      this.needBind = res.data.doctor_id ? false : true
      if(res.data.doctor_id){
        this.isContinue = false
      }
      window.sessionStorage.setItem('userInfo',JSON.stringify(res.data))
      window.localStorage.setItem('userInfo',JSON.stringify(res.data))
    }, 
    addCart(item) {
      console.log('触发添加购物车')
      
      let needPush = true;
      console.log(item)
      this.myCart.items.forEach((x, index) => {
        if (x.id == item.id) {
          this.myCart.items[index].count += Number(item.count);
          this.myCart.items[index].price = Number(x.original_price) * Number(this.myCart.items[index].count);
          needPush = false;
        }
      });
      if (needPush) {
        this.myCart.items.push({
          count: item.count,
          id: item.id,
          images: item.images,
          title: item.title,
          original_price: item.original_price,
          price: Number(item.original_price) * Number(item.count)
        });
      }
      window.sessionStorage.setItem("myCart", JSON.stringify(this.myCart));
      this.editCart()
    },
    async editCart(){
      console.log(this.myCart)
      let items = this.myCart.items.map(x=>{
        x.goods_id = x.id
        x.image = x.images
        return x
      })
      let {data:res} = await this.$http.post('/api/editGoodsCart',{items})
      if(res.statusCode !== 0){
        this.$toast.fail(res.msg)
      }
      this.$toast.success('加入购物车成功')
    },
  }
};
</script>
<style lang="less" scoped>
:root{
  font-size: 14px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  font-size: 14px;
  box-sizing: border-box;
  font-family: SourceHanSansCN-Regular;
}

#nav {
  height: 50px;
  padding: 0 10px;
  //background-color: rgb(245, 115, 110);
  background-color: #e9e8ee;
  color: #999;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  
  .nav_bar {
    position: absolute;
    background-color: #f5736e;
    width: 100%;
    height: 100%;
    left: 0;
    transition: all 1s;
    //margin-left: -20vw;
    margin-left: -100vw;
    display: flex;
    padding-left: 30vw;
    justify-content: space-between;
    align-items: center;
    .bar_item{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .show_bar{
    margin-left: -20vw;
  }
  > div {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
    &.router-link-active {
      color: #eee;
    }
  }
}
.wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.block {
  margin-top: 100px;
  height: 50px;
}
</style>
