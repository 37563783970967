import Vue from 'vue'
import {
  Lazyload,
  Search,
  Icon,
  Popover,
  Popup,
  Swipe,
  SwipeItem,
  Image,
  Loading,
  Cell,
  CellGroup,
  ActionSheet,
  Stepper,
  Empty,
  CheckboxGroup,
  Checkbox,
  Toast,
  RadioGroup,
  Radio,
  AddressEdit,
  AddressList,
  Tab,
  Tabs
} from 'vant'

Vue
.use(Lazyload)
.use(Search)
.use(Icon)
.use(Popover)
.use(Swipe)
.use(SwipeItem)
.use(Image)
.use(Loading)
.use(Cell)
.use(CellGroup)
.use(ActionSheet)
.use(Stepper)
.use(Empty)
.use(Checkbox)
.use(CheckboxGroup)
.use(RadioGroup)
.use(Radio)
.use(AddressEdit)
.use(AddressList)
.use(Tab)
.use(Tabs)
.use(Popup)


// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});
export default Vue