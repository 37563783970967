<template>
  <div class="goods_pre_box" @click="$router.push('/detail?goods_id=' + item.id)">
    <div class="img_pre" >
      <div class="goods_img">
        <img :src="item.images" alt="" style="width:100%;height:100%;object-fit:cover;">
      </div>
    </div>
    <div class="msg_pre">
      <div class="msg_title">
        <div>
          {{item.title}}
        </div>
        <div @click.stop="addCart(item)">
          <van-icon name="shopping-cart" size="25" color="#f5736e"  />
        </div>
      </div>
      <div class="msg_dsc" v-if="false">描述</div>
      <div class="msg_buy">
        <div class="msg_price">￥{{item.original_price}}</div>
        <div @click.stop="buy">
          <my-btn content="立即购买"></my-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyBtn from "@/components/common/MyBtn.vue";
export default {
  props:['item'],
  components: {
    MyBtn
  },
  data(){
    return {
      goodsList:[
        
      ]
    }
  },
  methods: {
    addCart(item){
      this.$bus.$emit('add-cart',item)
    },
    buy(){
      let item = this.item
      item.count = 1
      item.price = item.original_price
      this.$bus.$data.order = [item]
      this.$router.push('/confirmorder')
    }
  }
};
</script>

<style lang="less" scoped>
.goods_pre_box {
  padding: 20px;
  display: flex;
  .img_pre {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    .goods_img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      background-color: #eee;
    }
  }
  .msg_pre {
    width: 100%;
    padding-left:20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .msg_title {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
    }
    .msg_buy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .msg_price {
        font-size: 18px;
        color: red;
        margin-left: -4px;
      }
 
    }
  }
}
</style>